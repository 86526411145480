import React, { useEffect, useContext } from 'react'

import '../styles/common.scss';
import { slide1, slide2 } from "../assets/images";

import { FirstScreen } from "../components/first-screen";
import { Header } from '../components/header';
import { VideoBlock } from "../components/video-block";
import { ForthScreen } from "../components/forth-screen";
import { FifthScreen } from "../components/fifth-screen";
import { SixthScreen } from "../components/sixth-screen";
import { Footer } from "../components/footer";
import { Subscribe } from "../components/subscribe";
import { Tribe } from '../components/tribe';
import { GeniusMini } from "../components/genius-mini";
import { Slide } from "../components/slide";
import { Media } from '../components/media';
import { Reviews } from "../components/reviews";
import { CustomPipe } from "../components/custom-pipe";
import { AppContext } from "../components/AppContainer";
import { Modal } from "../components/common/modal";
import { Lifestyle } from "../components/lifestyle";
import { Loader } from "../components/common/loader";
import { Smoke } from "../components/first-screen/smoke";
import { MobilePopupContainer } from '../components/mobile-popup';


function Home() {
    const { modal, isLoading, isOrientationChanged, visibleMenuPopup, setVisibleMenuPopup, activePopupItem, setActivePopupItem } = useContext(AppContext);


    useEffect(() => {
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }
    }, []);


    return (
        <>
            <Header />
            <div className="App">

                {modal && <Modal />}
                {
                    isLoading || isOrientationChanged
                        ? <Loader />
                        : (
                            <>

                                <MobilePopupContainer
                                    visible={visibleMenuPopup}
                                    setVisible={setVisibleMenuPopup}
                                    activePopupItem={activePopupItem}
                                    setActivePopupItem={setActivePopupItem} />
                                <FirstScreen />

                                <Smoke />
                                <CustomPipe />

                                <VideoBlock />
                                <ForthScreen />
                                <FifthScreen />
                                <SixthScreen />
                                <GeniusMini />
                                <Slide image={slide1} />
                                <Media />
                                <Slide image={slide2} />
                                <Reviews />
                                <Lifestyle />
                                <Tribe />
                                <Subscribe />
                                <Footer />
                            </>
                        )
                }
            </div>
        </>

    );
}

export default Home
