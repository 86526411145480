const initialState = {
    totalCount: 0,
    totalPrice: 0,
    items: []
}

function Cart(state = initialState, action: any) {
    switch (action.type) {
        case "setCartData":
            debugger

            return {
                ...state,
                ...action.payload
            }
        default:
            return state
    }
}

export default Cart