import React, { FC, useContext, useEffect } from "react";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { currentFrame, currentFrameMobile } from "../../../utils";
import styles from './Canvas.module.scss';
import { AppContext } from "../../AppContainer";
import { CanvasFirstScreenProps } from "../../../models/canvasFirstScreenProps";
import { ScrollTriggerIds } from "../../../models/scrollTriggerIds";

gsap.registerPlugin(ScrollTrigger);

const COUNT_FRAMES = 200; //437

export const Canvas: FC<CanvasFirstScreenProps> = ({ descriptionBlock, canvasBlock, firstScreen }) => {
    const { featureBlock, isMobile, isIPad, setIsOrientationChanged } = useContext(AppContext);

    const size = function () {
        if (isIPad && !isMobile) return 700;
        const maxSize = 1000;
        const size = window.innerWidth > window.innerHeight ? window.innerHeight : window.innerWidth;
        return size > maxSize ? maxSize : size;
    }();

    useEffect(() => {
        const onOrientationChange = () => {
            setTimeout(() => {
                window.location.reload()
            }, 100);
            setIsOrientationChanged(true);
        }

        window.addEventListener("orientationchange", () => onOrientationChange());
        return () => window.removeEventListener("orientationchange", () => onOrientationChange());
    }, []);

    useEffect(() => {
        if (canvasBlock.current) {
            //render canvas with images
            const context = canvasBlock.current.getContext("2d");

            canvasBlock.current.width = size;
            canvasBlock.current.height = isMobile ? window.innerHeight : size;
            const images: Array<HTMLImageElement> = [];
            const frames = { frame: 0 };

            for (let i = 0; i < COUNT_FRAMES; i++) {
                const img = new Image();
                img.src = isMobile ? currentFrameMobile(i) : currentFrame(i);
                images.push(img);
            }

            const render = () => {
                if (context && canvasBlock.current) {
                    if (isMobile) {
                        canvasBlock.current.height = frames.frame > 132 ? size : window.innerHeight;
                    } else {
                        canvasBlock.current.height = size
                    }

                    context.clearRect(0, 0, canvasBlock.current.width, canvasBlock.current.height);
                    context.drawImage(
                        images[frames.frame],
                        0,
                        0,
                        size,
                        canvasBlock.current.height
                    );
                }
            }

            gsap.to(frames, {
                ease: 'slow',
                frame: COUNT_FRAMES - 1,
                // @ts-ignore
                // mistake of type in the library
                snap: 'frame',
                scrollTrigger: {
                    id: ScrollTriggerIds.CanvasFirstScreenRenderTrigger,
                    trigger: firstScreen.current,
                    start: "top top",
                    end: `+=${window.innerHeight * 10.7}`,
                    scrub: 0.5,
                },
                onUpdate: render
            });

            images[0].onload = render;

            if (!isMobile) {
                moveCanvas(-300, 0);
                increaseCanvas();
            }

            if (isMobile) {
                moveCanvas(0, -200);
            }

            disappearCanvas();

            return () => {
                ScrollTrigger.getById(ScrollTriggerIds.CanvasFirstScreenRenderTrigger).kill();
                ScrollTrigger.getById(ScrollTriggerIds.CanvasFirstScreenDisappearTrigger).kill();

                if (!isMobile) {
                    ScrollTrigger.getById(ScrollTriggerIds.CanvasFirstScreenMoveTrigger).kill();
                    ScrollTrigger.getById(ScrollTriggerIds.CanvasFirstScreenDecreaseTrigger).kill();
                }
            }
        }
    }, []);

    const increaseCanvas = () => {

        const positionX = isIPad && !isMobile ? -200 : -300;
        const canvasDecreaseTimeLine = gsap.timeline({
            scrollTrigger: {
                id: ScrollTriggerIds.CanvasFirstScreenDecreaseTrigger,
                trigger: featureBlock.current,
                start: "top top+=100",
                scrub: 0.5,
            },
        });

        canvasDecreaseTimeLine.to(canvasBlock.current, { x: positionX, transform: 'scale(0.8)', duration: 10 });
    }

    const disappearCanvas = () => {
        const disappearedCanvasTimeLine = gsap.timeline({
            scrollTrigger: {
                id: ScrollTriggerIds.CanvasFirstScreenDisappearTrigger,
                trigger: firstScreen.current,
                start: `bottom bottom+=500`,
                end: 'bottom bottom',
                scrub: 0.5,
            }
        });

        disappearedCanvasTimeLine.to(canvasBlock.current, { opacity: 0 })
    }

    const moveCanvas: (x: number, y: number) => void = (x = 0, y = 0) => {
        const canvasTimeLine = gsap.timeline({
            scrollTrigger: {
                id: ScrollTriggerIds.CanvasFirstScreenMoveTrigger,
                trigger: descriptionBlock.current,
                start: "bottom bottom",
                endTrigger: firstScreen.current,
                end: "+=500",
                scrub: 0.5,
            }
        });
        canvasTimeLine.from(canvasBlock.current, { x: 0, y: 0 });
        canvasTimeLine.to(canvasBlock.current, { x, y, duration: 30, delay: 30 });
    }

    return (
        <div className={styles.canvas}>
            <canvas ref={canvasBlock} />
        </div>
    );
};
