

// FixedCustomPipeReducer

export const fixedPipeAC = (payload: boolean) => {
    return { type: "changeVisibleFixedPipe", payload }
}

export const changeCustomValue1AC = (payload: any) => {
    return { type: "changeCustomValue1", payload }
}

export const changeCustomValue2AC = (payload: any) => {
    return { type: "changeCustomValue2", payload }
}

export const changeCountLinesAC = (payload: any) => {
    return { type: "cahngeCountLines", payload }
}

// customPipeReducer
export const changeActiveTabAC = (payload: number) => {
    return { type: "setActiveTab", payload }
}


// cart


export const setCartData = (payload: any) => {
    return { type: "setCartData", payload }
}
